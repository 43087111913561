export default function MincaCenteredLogo({
  children
}: {
  children: React.ReactNode;
}) {
  return <div className="flex flex-col flex-1 items-center pt-24 max-w-xl mx-auto" data-sentry-component="MincaCenteredLogo" data-sentry-source-file="MincaCenteredLogo.tsx">
      <div className="mb-12">
        <img src="/images/minca_logo.png" alt="logo" />
      </div>
      {children}
    </div>;
}